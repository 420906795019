import { http } from '@/http';
import { sessionParam } from '@/services/getSessionParam';

const cart = {
  namespaced: true,
  state: {
    total: {
      amount: 0,
      count: 0,
    },
    products: {},
    vouchers: {},
    appliedCoupon: null,
    availableCoupons: [],
    navCartOpen: false,
    loading: false,
    couponLoading: false,
    inputErrors: {},
    settings: {},
  },

  mutations: {
    setCart(state, payload) {
      state.total = payload.total ? payload.total : { amount: 0, count: 0 };
      state.products = payload.products ? payload.products : {};
      state.vouchers = payload.vouchers ? payload.vouchers : {};
      state.availableCoupons = payload.available_coupons;
      state.appliedCoupon = payload.applied_coupon
        ? payload.applied_coupon
        : null;

      state.loading = false;
      state.couponLoading = false;
      state.settings = payload.settings ? payload.settings : {};
      state.inputErrors = {};
    },
    setCartItems(state, payload) {
      state.total = payload.total ? payload.total : { amount: 0, count: 0 };
      state.products = payload.products ? payload.products : {};
      state.vouchers = payload.vouchers ? payload.vouchers : {};
      state.loading = false;
    },
    setCartAfterCoupon(state, payload) {
      state.total = payload.total ? payload.total : { amount: 0, count: 0 };
      state.availableCoupons = payload.available_coupons
        ? payload.available_coupons
        : [];
      state.appliedCoupon = payload.applied_coupon
        ? payload.applied_coupon
        : null;
    },
    initializeDataFromStorage(state) {
      let cartDataString = localStorage.getItem('cartData');
      try {
        let data = JSON.parse(cartDataString);
        state.total = data.total
          ? data.total
          : {
              amount: 0,
              count: 0,
            };
        state.products = data.products ? data.products : {};
        state.vouchers = data.vouchers ? data.vouchers : {};
      } catch (error) {
        state.total = {
          amount: 0,
          count: 0,
        };
        state.products = {};
        state.vouchers = {};
      }
    },
    setNavCartOpen(state, payload) {
      state.navCartOpen = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setInputErrors(state, payload) {
      state.inputErrors = payload;
    },
    resetInputErrors(state) {
      state.inputErrors = {};
    },
  },
  actions: {
    async getItems({ commit }) {
      try {
        let params = sessionParam();
        commit('setLoading', true);
        const response = await http.post('cart', params);
        commit('setCart', response.data.results);
      } catch (error) {
        commit('setLoading', false);
      }
    },
    async addItem({ commit }, { item, isVoucher }) {
      try {
        let params = sessionParam();
        commit('setLoading', true);
        let response;
        if (!isVoucher) {
          response = await http.post('cart/' + item.id, params);
        } else {
          params.type = item.type;
          params.value = item.value;
          response = await http.post('cart/voucher', params);
        }
        commit('setCartItems', response.data.results);

      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
        commit('setLoading', false);
      }
    },
    async removeItem({ commit }, { itemId, isVoucher }) {
      try {
        let params = sessionParam();
        if (isVoucher) {
          params.type = 'voucher';
          params.value = itemId;
        }
        commit('setLoading', true);
        const response = await http.delete('cart/' + itemId, {
          data: params,
        });
        commit('setCart', response.data.results);
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
        commit('setLoading', false);
      }
    },
    async changeItemQuantity({ commit }, item) {
      try {
        let params = sessionParam();
        params.quantity = item.quantity;
        let type = item.value;
        if (item.isVoucher) {
          type = 'voucher';
          params.type = type;
          params.value = item.value;
        }
        commit('setLoading', true);
        const response = await http.put(
          'cart/' + type + '/change-quantity',
          params
        );
        commit('setCart', response.data.results);
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
        commit('setLoading', false);
      }
    },

    async applyCoupon({ commit }, code) {
      try {
        let params = sessionParam();
        params = { ...params, type: 'cart' };

        const encodedCode = encodeURIComponent(code);

        const response = await http.post(
          'promo-codes/' + encodedCode + '/apply',
          params
        );

        commit('setCart', response.data.results);
      } catch (error) {
        commit('setLoading', false);
      }
    },


    async deactivateCoupon({ commit }, code) {
      try {
        let params = sessionParam();
        params = { ...params, type: 'cart' };

        const encodedCode = encodeURIComponent(code);

        const response = await http.post(
          'promo-codes/' + encodedCode + '/decline',
          params
        );
        commit('setCart', response.data.results);
      } catch (error) {
        commit('setLoading', false);
      }
    },
  },
};
export default cart;
