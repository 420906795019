import VueCookies from 'vue-cookies';

import { generateToken } from '@/services/generateToken';
import store from '@/store';

export const initialize = function () {
  if (!VueCookies.get('token') && !localStorage.session_key) {
    localStorage.setItem('session_key', generateToken());
  }
  store.commit('compare/initializeDataFromStorage');
  store.commit('cart/initializeDataFromStorage');
  store.commit('wishlist/initializeDataFromStorage');
};
