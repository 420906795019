import { translationJsons } from '@/services/getTranslationsJsons';

export default {
  ro: {
    name: 'Ro',
    messages: translationJsons(),
  },
  hu: {
    name: 'Hu',
    load: () => {
      return new Promise((resolve) => {
        resolve(translationJsons('hu'));
      });
    },
  },
};
