import { http } from '@/http';
import { getCompareData } from '@/services/getCompareData';

const compare = {
  namespaced: true,
  state: {
    comparedProducts: {
      products: [],
      attributeKeys: [],
    },
    toCompareData: {},
  },

  mutations: {
    setComparedProducts(state, payload) {
      state.comparedProducts.products = payload.products;
      state.comparedProducts.attributeKeys = payload.attribute_keys;
    },
    setToCompareData(state, compareToAdd) {
      state.toCompareData = getCompareData(compareToAdd, 'add');
    },
    removeFromCompareData(state, productCode) {
      state.toCompareData = getCompareData(productCode, 'remove');
    },
    initializeDataFromStorage(state) {
      state.toCompareData = localStorage.compare
        ? JSON.parse(localStorage.compare)
        : {};
    },
    resetCompareData(state) {
      state.toCompareData = {};
    },
  },
  actions: {
    async getProducts({ commit }, input) {
      try {
        const response = await http.post('products/compare', input);
        commit('setComparedProducts', response.data.results);
        localStorage.compare = JSON.stringify(
          response.data.results.storage_data
        );
      } catch (error) {
        //
      }
    },
  },
  getters: {
    compareCategory: (state) =>
      state.toCompareData ? Object.keys(state.toCompareData)[0] : '',
  },
};
export default compare;
