import VueCookies from 'vue-cookies';

import { generateToken } from '@/services/generateToken';
import store from '@/store';
export const resetUserData = function () {
  VueCookies.remove('token');
  let storage = localStorage.getItem('session_key');
  if (!storage) {
    localStorage.setItem('session_key', generateToken());
  }
  localStorage.removeItem('personType');
  localStorage.removeItem('paymentType');
  localStorage.removeItem('sameData');
  localStorage.removeItem('deliveryMethod');
  localStorage.removeItem('profile');
  store.commit('user/resetUserData');
  store.commit('auth/resetToken');
};
