import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/auth';
import cartStore from '@/store/cart';
import categoryStore from '@/store/category';
import checkoutStore from '@/store/checkout';
import compareStore from '@/store/compare';
import generalStore from '@/store/general';
import homeStore from '@/store/home';
import navigationStore from '@/store/navigation';
import orderStore from '@/store/order';
import pageStore from '@/store/page';
import webStoragePlugin from '@/store/plugins/webStorage';
import productStore from '@/store/product';
import searchStore from '@/store/search';
import userStore from '@/store/user';
import voucherStore from '@/store/voucher';
import wishlistStore from '@/store/wishlist';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [webStoragePlugin],
  modules: {
    auth: auth,
    home: homeStore,
    category: categoryStore,
    product: productStore,
    navigation: navigationStore,
    compare: compareStore,
    cart: cartStore,
    wishlist: wishlistStore,
    search: searchStore,
    general: generalStore,
    checkout: checkoutStore,
    order: orderStore,
    user: userStore,
    page: pageStore,
    voucher: voucherStore,
  },
});
