import 'bootstrap/scss/bootstrap.scss';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import './_scss/main.scss';
import 'vue-toast-notification/dist/theme-sugar.css';

import {
  BButton,
  BCol,
  BNavbar,
  BNavbarBrand,
  BNavbarNav,
  BNavbarToggle,
  BNavItem,
  BRow,
} from 'bootstrap-vue';
import Vue from 'vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { i18n } from 'vue-lang-router';
import VueMeta from 'vue-meta';
import VueMobileDetection from 'vue-mobile-detection';
import VueScreen from 'vue-screen';
import VueToast from 'vue-toast-notification';

import App from './App';
import router from './router';
import { initialize } from './services/initialize';
import store from './store';

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueToast);
Vue.use(VueMobileDetection);

Vue.component('BButton', BButton);
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarToggle', BNavbarToggle);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BCol', BCol);
Vue.component('BRow', BRow);
Vue.component('BNavItem', BNavItem);

Vue.use(VueDOMPurifyHTML);

Vue.use(VueScreen, {
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1440px',
});

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  beforeCreate() {
    initialize();
  },
  render: (h) => h(App),
}).$mount('#app');
