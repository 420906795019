export const checkSummaryStorage = function () {
  let permitedPaymentMethods = ['ramburs', 'online'];
  let permitedShippingMethods = [
    'fan-courier',
    'sameday',
    'predare-personala',
    'email',
  ];
  let permitedPersonTypes = ['individual', 'legal'];
  let permitedSameData = ['true', 'false'];
  let deliveryMethodString = localStorage.getItem('deliveryMethod');
  let deliveryMethod;

  try {
    deliveryMethod = JSON.parse(deliveryMethodString);
  } catch (error) {
    deliveryMethod = { code: 'error' };
  }

  let paymentType = localStorage.getItem('paymentType');
  let personType = localStorage.getItem('personType');
  let sameData = localStorage.getItem('sameData');

  return (
    permitedShippingMethods.includes(deliveryMethod.code) &&
    permitedPaymentMethods.includes(paymentType) &&
    permitedPersonTypes.includes(personType) &&
    permitedSameData.includes(sameData)
  );
};
