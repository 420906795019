import router from '@/router';
import { isEqual } from '@/services/isEqualJsons';

export const updateQuery = function (response, qParams) {
  const filtersArray = response.data.results.query_params;
  const categorySlug = response.data.results.query_params.category_slug;
  let responseFilters = response.data.results.filters;
  let updatedQuery = {};
  // update query based on filters
  Object.entries(filtersArray).forEach(([filterKey, filterValue]) => {
    if (Object.prototype.hasOwnProperty.call(responseFilters, filterKey)) {
      let filters = [];
      switch (typeof filterValue) {
        case 'object':
          if (filterKey == 'attributes') {
            filters = { attributes: filterValue };
          } else {
            filters = filterValue;
          }
          break;
        case 'string':
          filters = filterValue.split(',');
          break;
        default:
          break;
      }
      let currentFilter = responseFilters[filterKey].elements;
      // if price we have need a separate structure
      if (
        Object.prototype.hasOwnProperty.call(filters, 'min') &&
        Object.prototype.hasOwnProperty.call(filters, 'max')
      ) {
        updatedQuery[filterKey] = filters.min + '-' + filters.max;
        // if attributes we need another structure
      } else if (Object.prototype.hasOwnProperty.call(filters, 'attributes')) {
        Object.entries(filters.attributes).forEach(
          ([attributeCategory, valueArray]) => {
            valueArray.forEach((attributeKey) => {
              currentFilter.forEach((filter) => {
                filter.attributes.filter(function (elem) {
                  if (elem.key == attributeKey) {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        updatedQuery,
                        attributeCategory
                      )
                    ) {
                      // if filter already exist then extend it
                      updatedQuery[attributeCategory] =
                        updatedQuery[attributeCategory] + ',' + attributeKey;
                    } else {
                      // otherwise create it
                      updatedQuery[attributeCategory] = attributeKey;
                    }
                  }
                });
              });
            });
          }
        );

        // any other filters have a common structure
      } else {
        filters.forEach((value) => {
          currentFilter.filter(function (elem) {
            if (elem.key == value) {
              if (
                Object.prototype.hasOwnProperty.call(updatedQuery, filterKey)
              ) {
                // if filter already exist then extend it
                updatedQuery[filterKey] = updatedQuery[filterKey] + ',' + value;
              } else {
                // otherwise create it
                updatedQuery[filterKey] = value;
              }
            }
          });
        });
      }
    }
  });

  if (qParams.page && qParams.page != 1) {
    updatedQuery.page = qParams.page;
  }
  if (qParams.perPage) {
    updatedQuery.perPage = qParams.perPage;
  }
  if (
    qParams.sort &&
    qParams.sort != '' &&
    qParams.sort != 'sales-counter' &&
    response.data.results.query_params.sort == qParams.sort
  ) {
    updatedQuery.sort = qParams.sort;
  }
  if (qParams.key) {
    updatedQuery.key = qParams.key;
  }

  // remove from query params if the category slug is different example language change
  if (qParams.category_slug && categorySlug == qParams.category_slug) {
    updatedQuery.category_slug = qParams.category_slug;
  }
  let query = router.currentRoute.query;

  if (!isEqual(updatedQuery, query)) {
    let updatedQueryWithoutPagination = Object.assign({}, updatedQuery);
    delete updatedQueryWithoutPagination['page'];
    delete updatedQueryWithoutPagination['perPage'];

    let queryWithoutPagination = Object.assign({}, query);
    delete queryWithoutPagination['page'];
    delete queryWithoutPagination['perPage'];
    if (!isEqual(updatedQueryWithoutPagination, queryWithoutPagination)) {
      // if filter changed then reset pagination
      delete updatedQuery['page'];
    }
    // if there was a pagination and the perPage changed then reset page
    else if (
      !Object.prototype.hasOwnProperty.call(query, 'perPage') &&
      Object.prototype.hasOwnProperty.call(updatedQuery, 'perPage')
    ) {
      delete updatedQuery['page'];
    }
    router.push({
      query: updatedQuery,
    });
  } else if (updatedQuery.page > response.data.results.products.page.lastPage) {
    delete updatedQuery['page'];
    router.push({
      query: updatedQuery,
    });
  }
};
