import { http } from '@/http';

const home = {
  namespaced: true,
  state: {
    sliders: {
      description: '',
      title: '',
      elements: [],
    },
    featuredCategories: [],
    brands: {
      description: '',
      title: '',
      elements: [],
    },
    bestsellers: [],
    promotions: {},
    newProducts: [],
    loading: false,
  },

  mutations: {
    setHomeItems(state, payload) {
      state.sliders = payload.sliders;
      state.featuredCategories = payload.featured_categories;
      state.brands = payload.brands;
      state.bestsellers = payload.bestsellers;
      state.promotions = payload.promotions;
      state.newProducts = payload.new_products;
      state.loading = false;
    },
    startLoading(state) {
      state.loading = true;
    },
  },
  actions: {
    async getSections({ commit }) {
      try {
        commit('startLoading');
        const response = await http.get('home');
        commit('setHomeItems', response.data.results);
      } catch (error) {
        //
      }
    },
  },
};
export default home;
