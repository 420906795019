<template>
    <footer
        v-if="routerLoaded"
        :class="{ custom: isCustomHAF }"
    >
        <div
            v-if="!isCustomHAF"
            class="footer-top"
        >
            <div class="container">
                <div class="row d-flex">
                    <div class="col">
                        <router-link :to="{ name: 'Home' }">
                            <span>Pescario.ro</span>
                        </router-link>
                    </div>
                    <div class="col">
                        <span>{{ $t('footer.firstColumnHeading') }}</span>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'modalitati-de-plata' },
                            }"
                        >
                            {{ $t('footer.paymentInfoText') }}
                        </router-link>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'livrarea-comenzilor' },
                            }"
                        >
                            {{ $t('footer.shippingInfoText') }}
                        </router-link>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: $t('page.return.slug') },
                            }"
                        >
                            {{ $t('footer.returnInfoText') }}
                        </router-link>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: $t('page.guarantee.slug') },
                            }"
                        >
                            {{ $t('footer.guaranteeAndServiceInfoText') }}
                        </router-link>
                    </div>
                    <div class="col">
                        <span>{{ $t('footer.secondColumnHeading') }}</span>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'contact' },
                            }"
                        >
                            {{ $t('footer.contactUsText') }}
                        </router-link>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'despre-noi' },
                            }"
                        >
                            {{ $t('footer.aboutUsText') }}
                        </router-link>
                        <router-link
                            :to="{ name: 'Pagina', params: { slug: 'faq' } }"
                        >
                            {{ $t('footer.faqText') }}
                        </router-link>
                    </div>
                    <div class="col">
                        <span>{{ $t('footer.thirdColumnHeading') }}</span>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'termeni-si-conditii' },
                            }"
                        >
                            {{ $t('footer.termsText') }}
                        </router-link>
                        <router-link
                            :to="{ name: 'Pagina', params: { slug: 'gdpr' } }"
                        >
                            {{ $t('footer.gdprText') }}
                        </router-link>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'cookie-uri' },
                            }"
                        >
                            {{ $t('footer.cookiesText') }}
                        </router-link>
                        <a
                            href="https://anpc.ro"
                            target="_blank"
                        > ANPC </a>
                    </div>
                    <div class="col">
                        <span>{{ $t('footer.fourthColumnHeading') }}</span>
                        <a
                            class="d-flex"
                            href="https://www.facebook.com/profile.php?id=61564971162990"
                            target="_blank"
                        >
                            <img
                                src="/images/icon-facebook.svg"
                                alt="facebook"
                            >
                            Facebook
                        </a>
                        <a
                            class="d-flex"
                            href="https://www.instagram.com/pescario.ro/?igsh=NHE5MW1lcHowZGJo&utm_source=qr"
                            target="_blank"
                        >
                            <img
                                src="/images/icon-instagram.svg"
                                alt="instagram"
                            >

                            Instagram
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="!isCustomHAF"
            class="footer-bottom"
        >
            <div class="container">
                <div>
                    <div class="d-flex">
                        <div
                            v-if="!isMobile"
                            class="d-flex footer-lang"
                        >
                            <globe-icon class="sm" />
                            <p>Ron | {{ lang }}</p>
                        </div>
                        <div class="copyright">
                            <p>
                                {{ $t('footer.copyrightText', { year: year }) }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="anpc">
                    <a
                        href="https://anpc.ro/ce-este-sal/"
                        target="_blank"
                    >
                        <img
                            src="/images/anpc-sal.webp"
                            alt="Soluționarea Alternativă a Litigiilor"
                        >
                    </a>
                    <a
                        href="https://ec.europa.eu/consumers/odr"
                        target="_blank"
                    >
                        <img
                            src="/images/anpc-sol.webp"
                            alt="Soluționarea online a litigiilor"
                        >
                    </a>
                </div>
            </div>
        </div>
        <div
            v-else
            class="custom-footer"
        >
            <div class="container">
                <cart-info />
                <div class="footer-info mt-4">
                    <div>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'modalitati-de-plata' },
                            }"
                        >
                            {{ $t('footer.paymentInfoText') }}
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'livrarea-comenzilor' },
                            }"
                        >
                            {{ $t('footer.shippingInfoText') }}
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'formular-returnare-produs' },
                            }"
                        >
                            {{ $t('footer.returnInfoText') }}
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{ name: 'Pagina', params: { slug: 'faq' } }"
                        >
                            {{ $t('footer.faqText') }}
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{
                                name: 'Pagina',
                                params: { slug: 'contact' },
                            }"
                        >
                            {{ $t('footer.contactUsText') }}
                        </router-link>
                    </div>
                </div>
                <div class="footer-copyright mt-4">
                    <p>
                        {{ $t('footer.copyrightText', { year: year }) }}
                    </p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    import GlobeIcon from 'vue-feather-icons/icons/GlobeIcon';
    import { i18n } from 'vue-lang-router';
    import { mapState } from 'vuex';

    import CartInfo from '@/pages/cart/CartInfo';

    export default {
        components: { CartInfo, GlobeIcon },
        name: 'AppFooter',
        computed: {
            ...mapState('general', ['isCustomHAF', 'routerLoaded']),
            lang() {
                return i18n.locale == 'ro' ? 'Română' : 'Magyar';
            },
            isMobile() {
                return this.$screen.breakpoint == 'md';
            },
            year() {
                let currentDate = new Date();
                return currentDate.getFullYear();
            },
        },
    };
</script>

<style scoped lang="scss">
footer {
    color: $white;
    span {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 24px;
        display: block;
    }
    a {
        color: $white;
        display: block;
        font-size: 12px;
        font-weight: 600;
        margin-bottom: 12px;
        &:last-of-type {
            margin: 0;
        }
    }
    .footer-top {
        background-color: $primary;
        padding: 40px 0px;
        .row {
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                .col {
                    margin-bottom: 24px;
                    &:first-of-type {
                        span {
                            margin: 0;
                        }
                    }
                    &:last-of-type {
                        margin: 0;
                    }
                }
            }
            .col {
                @include media-breakpoint-down(md) {
                    &:first-of-type {
                        flex-basis: auto;
                    }
                }
                &:last-of-type {
                    img {
                        height: 18px;
                    }
                }
            }
        }
    }
    .footer-bottom {
        background-color: $primary-dark;
        padding: 15px 0px;
        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
                .footer-lang {
                    align-items: center;
                    width: fit-content;
                    span {
                        margin: 0;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }
                &:first-of-type {
                    svg {
                        color: $white;
                        stroke-width: 1px;
                    }
                }
                &.anpc {
                    display: flex;
                    justify-content: flex-end;
                    gap: 8px;
                    @include media-breakpoint-between(sm, md) {
                        justify-content: flex-start;
                    }
                    @include media-breakpoint-down(sm) {
                        flex-direction: column;
                    }
                    a {
                        margin: 0;
                        img {
                            height: 32px;
                            margin: 0;
                        }
                    }
                }
            }
        }
        a,
        p {
            font-weight: 400;
            font-size: 12px;
            color: $white;
        }
    }

    &.custom {
        color: $gray;
        border-top: 1px solid $gray-thin;
        .custom-footer {
            .footer-info {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                justify-content: center;
                @include media-breakpoint-down(md) {
                    gap: 8px;
                    > div {
                        width: 100%;
                    }
                }
                a {
                    color: $black;
                    p {
                        font-size: 16px;
                    }
                }
            }
            .footer-copyright {
                padding-bottom: 24px;
                p {
                    color: $gray-dark;
                    @include media-breakpoint-up(md) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>
