import VueCookies from 'vue-cookies';

import { http } from '@/http';
import { resetStorage } from '@/services/resetStorage';

const AuthStore = {
  namespaced: true,
  state: {
    token: VueCookies.get('token'),
    registered: false,
    reSent: false,
    resetToken: '',
    resetEmail: '',
    redirectPath: '',
    inputErrors: {},
    confirmed: false,
    verificationEmail: '',
    possibilityToReSend: false,
  },

  mutations: {
    setToken(state, payload) {
      state.token = payload.token;
    },
    resetToken(state) {
      state.token = null;
    },
    setConfirmed(state, confirmed) {
      state.confirmed = confirmed;
    },
    setReSent(state, reSent) {
      state.reSent = reSent;
    },
    setRegistered(state) {
      state.registered = !state.registered;
    },
    setpossibilityToReSend(state, payload) {
      state.possibilityToReSend = payload;
    },
    setRegisteredEmail(state, email) {
      state.verificationEmail = email;
    },
    setRedirectPath(state, payload) {
      state.redirectPath = payload;
    },
    resetRedirectPath(state) {
      state.redirectPath = '';
    },
    setInputErrors(state, payload) {
      state.inputErrors = payload;
    },
    resetInputErrors(state) {
      state.inputErrors = {};
    },
  },
  actions: {
    async register({ commit }, registerData) {
      try {
        let response = await http.post('/auth/register', registerData);
        commit('setRegisteredEmail', response.data.results.email_address);
        commit('setRegistered');
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },
    async verify({ commit }, token) {
      try {
        await http.get('/auth/email/verify/' + token);
        commit('setConfirmed', true);
      } catch (error) {
        //
      }
    },
    async reSendVerification({ commit }, emailAddress) {
      try {
        await http.post('/auth/email/resend', {
          email_address: emailAddress,
        });
        commit('setRegisteredEmail', emailAddress);
        commit('setpossibilityToReSend', false);
      } catch (error) {
        //
      }
    },
    async login({ commit }, loginData) {
      try {
        let response = {};
        response = await http.post('/auth/login', {
          email: loginData.email,
          password: loginData.password,
          session_key: localStorage.getItem('session_key'),
        });
        VueCookies.set(
          'token',
          response.data.results.token,
          response.data.results.expires_in
        );
        localStorage.removeItem('session_key');
        commit('setToken', response.data.results);
        commit('cart/setCart', response.data.results.user.cart, { root: true });
        commit('wishlist/setWishlist', response.data.results.user.wishlist, {
          root: true,
        });
        commit('resetInputErrors');
      } catch (error) {
        if (error.response.status === 422) {
          commit('setInputErrors', { general: [error.response.data.message] });
        } else if (error.response.status === 403) {
          commit('setInputErrors', { general: [error.response.data.message] });
          commit('setpossibilityToReSend', true);
        } else {
          commit('setInputErrors', error.response.data.errors);
        }
      }
    },
    async googleUrlRequest() {
      try {
        let response = {};
        response = await http.get('/auth/google');
        window.location.href = response.data.results.request_url;
      } catch (error) {
        //
      }
    },

    async loginWithGoogle({ commit }, code) {
      try {
        let response = {};
        response = await http.post('/auth/google/callback?code=' + code, {
          session_key: localStorage.getItem('session_key'),
        });
        VueCookies.set(
          'token',
          response.data.results.token,
          response.data.results.expires_in
        );
        localStorage.removeItem('session_key');
        commit('setToken', response.data.results);
        commit('cart/setCart', response.data.results.user.cart, { root: true });
        commit('wishlist/setWishlist', response.data.results.user.wishlist, {
          root: true,
        });
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', { general: [error.response.data.message] });
      }
    },

    async facebookUrlRequest() {
      try {
        let response = {};
        response = await http.get('/auth/facebook');
        window.location.href = response.data.results.request_url;
      } catch (error) {
        //
      }
    },

    async loginWithFacebook({ commit }, code) {
      try {
        let response = {};
        response = await http.post('/auth/facebook/callback?code=' + code, {
          session_key: localStorage.getItem('session_key'),
        });
        VueCookies.set(
          'token',
          response.data.results.token,
          response.data.results.expires_in
        );
        localStorage.removeItem('session_key');
        commit('setToken', response.data.results);
        commit('cart/setCart', response.data.results.user.cart, { root: true });
        commit('wishlist/setWishlist', response.data.results.user.wishlist, {
          root: true,
        });
        commit('resetInputErrors');
      } catch (error) {
        //
      }
    },

    async logout({ commit }) {
      try {
        if (VueCookies.get('token')) {
          await http.post('/auth/logout');
          commit('resetToken');
          resetStorage();
          location.reload();
        }
      } catch (error) {
        //
      }
    },
    async requestResetToken({ commit }, data) {
      try {
        await http.post('/auth/password/reset', data);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },
    async resetPassword({ commit }, registerData) {
      try {
        await http.put('/auth/password/change', registerData);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },
    setToRedirectPathAfterLogin({ commit }, path) {
      commit('setRedirectPath', path);
    },
  },
  getters: {
    isLogged: (state) => !!state.token,
  },
};
export default AuthStore;
