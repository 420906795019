import Vue from 'vue';
import { i18n } from 'vue-lang-router';

import { resetStorage } from '@/services/resetStorage';

/**
 * Use this for errors
 * @param message
 */
export function toastError(message) {
  toastMessage(message, 'error', 8000);
}

/**
 * Use this for success
 * @param message
 */
export function toastSuccess(message) {
  toastMessage(message, 'success', 3000);
}

/**
 * Use this for info
 * @param message
 */
export function toastInfo(message) {
  toastMessage(message, 'info', 3000);
}

/**
 * Use this for generic messages
 * @param message
 */
export function toast(message) {
  toastMessage(message, '', 3000);
}

/**
 * Use this for customized messages (custom type and custom timeout (default: no timeout))
 * @param message
 * @param type // default: '' - generic
 * @param timeout // default: no timeout
 */
export function toastMessage(message, type = '', timeout = -1) {
  Vue.$toast.open({
    message: message,
    type: type,
    duration: timeout,
  });
}
export function handleUnprocessable(error) {
  const errors = error.response.data;
  let errorMessage = '';
  for (let key in errors) {
    errorMessage = Array.isArray(errors[key])
      ? errors[key].join('<br />')
      : errors[key].message || errors[key];

    if (typeof errorMessage !== 'string') {
      errorMessage = '';
      try {
        for (let prop in errors[key]) {
          if (Object.prototype.hasOwnProperty.call(errors[key], prop)) {
            for (let i = 0; i < errors[key][prop].length; ++i) {
              errorMessage = errorMessage + `${errors[key][prop][i]} <br/>`;
            }
          }
        }
      } catch (e) {
        errorMessage = JSON.stringify(errors[key]);
      }
    }
  }
  toastError(errorMessage, 'warning');
}

export function handleServerErrors(message) {
  toastError(message);
}

export function handleBadRequest(message) {
  toastError(message);
}

export function handleForbidden(message) {
  toastError(message);
}

export function handleUnauthorized(error) {
  resetStorage();
  toastError(error.response.data.message);
}

export function handleNotFound(e) {
  if (e.response.data.error) {
    if (e.response.data.message) {
      toastError(e.response.data.message);
    }
  } else if (e.response.message) {
    toastError(e.response.data.message);
  } else {
    toastError(e.response.statusText);
  }
}

export function handleNetworkError() {
  let lang = i18n.locale;
  toastError(i18n.messages[lang].page.noNetwork.heading);
}
