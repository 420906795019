import { http } from '@/http';
import router from '@/router';

const product = {
  namespaced: true,
  state: {
    category: {},
    product: {},
    related: [],
    reviews: {},
    questionsAndAnswers: {},
    qaaParent: null,
    loading: false,
    inputErrors: {},
    stateLoad: false,
    lastVisitedSlug: '',
    modal: {
      show: false,
      type: '',
      data: {},
    },
  },

  mutations: {
    setProductDetails(state, payload) {
      state.category = payload.category;
      state.product = payload.product;
      state.related = payload.related;
      state.loading = false;
    },
    setProductReviews(state, payload) {
      state.reviews = payload;
    },
    setQuestionsAndAnswers(state, payload) {
      state.questionsAndAnswers = payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    setQAAParent(state, payload) {
      state.qaaParent = payload;
    },
    setInputErrors(state, payload) {
      state.inputErrors = payload;
    },
    resetInputErrors(state) {
      state.inputErrors = {};
    },
    setModal(state, payload) {
      state.modal.show = payload.show;
      state.modal.type = payload.type;
      state.modal.data = payload.data ? payload.data : {};
    },
    setStateLoad(state, payload) {
      state.stateLoad = payload;
    },
    setLastVisitedSlug(state, payload) {
      state.lastVisitedSlug = payload;
    },
  },
  actions: {
    async getProduct({ commit }) {
      try {
        let params = router.currentRoute.params;
        commit('startLoading');
        const response = await http.get('products/' + params.slug);
        commit('setProductDetails', response.data.results);
        commit('setStateLoad', false);
        commit('setLastVisitedSlug', params.slug);
      } catch (error) {
        router.push({ name: 'NotFound' }).catch(() => {
          //
        });
      }
    },
    async getReviews({ commit }, queryObject = null) {
      try {
        let params = router.currentRoute.params;
        let queryParams = {};

        if (queryObject) {
          queryParams.page = queryObject.page;
        }

        const response = await http.post(
          'products/' + params.slug + '/reviews',
          queryParams
        );

        commit('setProductReviews', response.data.results);
      } catch (error) {
        //
      }
    },
    async addRating({ commit }, input) {
      try {
        await http.post('products/' + input.productId + '/rate', {
          rating: input.rating,
          message: input.message,
        });
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },
    async sendQuestion({ state, commit }, input) {
      try {
        if (state.qaaParent) {
          input.parent_id = state.qaaParent;
        }
        await http.post('products/' + input.productId + '/ask', input);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },
    async getQuestionsAndAnswers({ commit }, queryObject) {
      try {
        let params = router.currentRoute.params;
        let queryParams = {};

        if (queryObject) {
          queryParams.page = queryObject.page;
        }
        const response = await http.post(
          'products/' + params.slug + '/questions',
          queryParams
        );
        commit('setQuestionsAndAnswers', response.data.results);
      } catch (error) {
        //
      }
    },
  },
};
export default product;
