import { http } from '@/http';

const OrderStore = {
  namespaced: true,
  state: {
    ordersData: {},
    orderDetail: {},
    paymentStatus: {
      message: '',
      orderId: '',
      status: '',
      attempt: 0,
    },
    pagination: {
      page: 1,
      perPage: 6,
    },
  },

  mutations: {
    setOrderHistory(state, payload) {
      state.ordersData = payload;
    },
    setOrderDetails(state, payload) {
      state.orderDetail = payload;
    },
    setPaymentStatus(state, payload){
      state.paymentStatus.message = payload.message;
      state.paymentStatus.orderId = payload.order_id;
      state.paymentStatus.status = payload.status;
      state.paymentStatus.attempt = payload.attempt;
    },
    setPage(state, payload) {
      state.pagination.page = payload;
    },
    setPerPage(state, payload) {
      state.pagination.perPage = payload;
    },
  },
  actions: {
    async getOrderHistory({ state, commit }) {
      try {
        let queryParam =
          '?page=' +
          state.pagination.page +
          '&perPage=' +
          state.pagination.perPage;
        const response = await http.get('my-account/orders' + queryParam);
        commit('setOrderHistory', response.data.results);
      } catch (error) {
        //
      }
    },
    async getOrderDetails({ commit }, id) {
      try {
        const response = await http.get('my-account/orders/' + id);
        commit('setOrderDetails', response.data.results);
      } catch (error) {
        //
      }
    },
    async getPaymentStatus({ commit }, data) {
      try {
        const response = await http.post('payment/status', data);
        commit('setPaymentStatus', response.data.results);
      } catch (error) {
        //
      }
    },
  },
};
export default OrderStore;
