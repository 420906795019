<template>
    <header>
        <navbar />
    </header>
</template>

<script>
    import Navbar from '@/components/layouts/AppNavbar';
    export default {
        name: 'AppHeader',
        components: {
            Navbar: Navbar,
        },
        computed: {
            isNotMobile() {
                return this.$screen.breakpoint != 'md';
            }
        },
    };
</script>
