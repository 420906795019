import VueCookies from 'vue-cookies';

import { generateToken } from '@/services/generateToken';
import store from '@/store';
export const resetStorage = function () {
  VueCookies.remove('token');
  localStorage.removeItem('cartData');
  localStorage.removeItem('wishlistData');
  localStorage.setItem('session_key', generateToken());
  localStorage.removeItem('personType');
  localStorage.removeItem('paymentType');
  localStorage.removeItem('sameData');
  localStorage.removeItem('deliveryMethod');
  localStorage.removeItem('profile');
  store.commit('cart/initializeDataFromStorage');
  store.commit('wishlist/initializeDataFromStorage');
  store.commit('user/resetUserData');
  store.commit('auth/resetToken');
};
