import { http } from '@/http';

const GeneralStore = {
  namespaced: true,
  state: {
    isCustomHAF: false,
    customNetworkHAF: false,
    cookieIsAccepted: false,
    showCookieBanner: false,
    cookieText: '',
    maintenanceMode: false,
    counties: {},
    cities: {},
    shippingDetails: {},
    noScroll: false,
    scrollBasedVisibility: false,
    routerLoaded: false,
    modal: {
      show: false,
      type: '',
      data: {}
    },
  },

  mutations: {
    setCustomHAF(state, payload) {
      state.isCustomHAF = payload;
    },
    setNoNetworkHAF(state, payload) {
      state.customNetworkHAF = payload;
    },
    setCounties(state, payload) {
      state.counties = payload;
    },
    setCities(state, payload) {
      state.cities = payload;
    },
    setShippingDetails(state, payload) {
      state.shippingDetails = payload;
    },
    setCookie(state, payload) {
      state.cookieIsAccepted = payload;
    },
    setCookieBanner(state, payload) {
      state.showCookieBanner = payload;
    },
    setCookiesText(state, payload) {
      state.cookieText = payload;
    },
    setMaintenanceMode(state, payload) {
      state.maintenanceMode = payload;
    },
    setRouterLoaded(state, payload) {
      state.routerLoaded = payload;
    },
    setScrollBasedComponentVisibility(state, payload) {
      state.scrollBasedVisibility = payload;
    },
    setModal(state, payload) {
      state.modal.show = payload.show;
      state.modal.type = payload.type;
      state.modal.data = payload.data ? payload.data : {};
    },
  },
  actions: {
    setCustomHeaderAndFooter({ commit }, isCustom) {
      commit('setCustomHAF', isCustom);
    },
    setNoNetworkHeaderAndFooter({ commit }, isCustom) {
      commit('setNoNetworkHAF', isCustom);
    },
    setMaintenance({ commit }) {
      commit('setMaintenanceMode', true);
    },
    setRouterLoadedFlag({ commit }, isLoaded) {
      commit('setRouterLoaded', isLoaded);
    },

    async checkMaintenance({ commit }) {
      try {
        await http.get('pages/maintenance');
      } catch (error) {
        if (error.response.status == 503) {
          commit('setMaintenanceMode', true);
        } else {
          commit('setMaintenanceMode', false);
        }
      }
    },
    async checkNetwork({ commit }) {
      try {
        await http.get('home');
        commit('setNoNetworkHAF', false);
      } catch (error) {
        let noNetwork = true;
        if (error.message != 'Network Error') {
          noNetwork = false;
        }
        commit('setNoNetworkHAF', noNetwork);
      }
    },
    async getCounties({ commit }) {
      try {
        if (!localStorage.getItem('counties')) {
          const response = await http.get('settings/counties');
          commit('setCounties', response.data.results);
        }
      } catch (error) {
        //
      }
    },
    async getCities({ commit }) {
      try {
        if (!localStorage.getItem('cities')) {
          const response = await http.get('settings/cities');
          commit('setCities', response.data.results);
        }
      } catch (error) {
        //
      }
    },
    async getShippingDetails({ commit }) {
      try {
        const response = await http.get('settings/shipping-details');
        commit('setShippingDetails', response.data.results);
      } catch (error) {
        //
      }
    },
    async getCookieBanner({ commit }) {
      try {
        const response = await http.get('settings/consent');
        commit('setCookiesText', response.data.results);
        commit('setCookieBanner', true);
      } catch (error) {
        //
      }
    },
  },
};
export default GeneralStore;
