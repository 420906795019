import { http } from '@/http';

const user = {
  namespaced: true,
  state: {
    deliveryData: [],
    billingData: [],
    companyData: [],
    summaryData: {},
    generalData: {},
    avatar: '',
    returnData: {},
    noOrders: true,
    reviews: {},
    vouchers: {},
    reviewPagination: {
      page: 1,
      perPage: 6,
    },
    inputErrors: {},
    summaryError: false,
    loading: false,
    modal: {
      show: false,
      type: '',
      data: {}
    }
  },

  mutations: {
    setUserData(state, payload) {
      state.deliveryData = payload.delivery_data;
      state.billingData = payload.billing_data;
      state.companyData = payload.company_data;
      state.generalData = payload.general_data;
      state.loading = false;
    },
    setUserReviews(state, payload) {
      state.reviews = payload;
      state.loading = false;
    },
    updateDeliveryData(state, payload) {
      state.deliveryData = payload;
    },
    updateBillingData(state, payload) {
      state.billingData = payload;
    },
    updateCompanyData(state, payload) {
      state.companyData = payload;
    },
    setGeneralData(state) {
      state.generalData.name = localStorage.getItem('profile')
        ? JSON.parse(localStorage.getItem('profile')).name
        : '';
      state.generalData.first_name = localStorage.getItem('profile')
        ? JSON.parse(localStorage.getItem('profile')).first_name
        : '';
      state.generalData.last_name = localStorage.getItem('profile')
        ? JSON.parse(localStorage.getItem('profile')).last_name
        : '';
      state.avatar = localStorage.getItem('profile')
        ? JSON.parse(localStorage.getItem('profile')).avatar
        : '';
    },
    updateGeneralData(state, payload) {
      state.generalData.name = payload.name;
      state.generalData.first_name = payload.first_name;
      state.generalData.last_name = payload.last_name;
      state.generalData.phone = payload.phone;
    },
    removeAvatar(state) {
      state.avatar = '';
    },
    setAvatar(state, payload) {
      state.avatar = payload;
    },
    updateAvatar(state, payload) {
      state.avatar = payload.url;
    },
    setReviewPage(state, payload) {
      state.reviewPagination.page = payload;
    },
    setReviewPerPage(state, payload) {
      state.reviewPagination.perPage = payload;
    },
    setReturnData(state, payload) {
      state.returnData = payload;
      if(Object.keys(payload).length > 0){
        state.noOrders = false;
      }

    },
    setUserVouchers(state, payload) {
      state.vouchers = payload;
      state.loading = false;
    },
    setInputErrors(state, payload) {
      state.inputErrors = payload;
    },
    resetInputErrors(state) {
      state.inputErrors = {};
    },
    resetUserData(state) {
      state.generalData.name = '';
      state.generalData.first_name = '';
      state.generalData.last_name = '';
      state.avatar = '';
      state.deliveryData = [];
      state.billingData = [];
      state.companyData = [];
      state.summaryData = {};
      state.returnData = {};
      state.reviews = {};
      state.vouchers = {};
    },
    startLoading(state) {
      state.loading = true;
    },
    setModal(state, payload) {
      state.modal.show = payload.show;
      state.modal.type = payload.type;
      state.modal.data = payload.data ? payload.data : {};
    },
    initUserState(state) {
      let profileString = localStorage.getItem('profile');
      let profile;

      try {
        profile = JSON.parse(profileString);
        state.generalData = {
          name: profile.name || '',
          first_name: profile.first_name || '',
          last_name: profile.last_name || '',
        };

        state.avatar = profile.avatar || '';
      } catch (error) {
        //
      }
    }
  },
  actions: {
    async getUserData({ commit }) {
      try {
        commit('startLoading');
        let general = await http.get('my-account');
        commit('setUserData', general.data.results);
      } catch (error) {
        //
      }
    },

    async getAvatar({ commit }) {
      try {
        let avatar = await http.get('my-account/avatar');
        commit('setAvatar', avatar.data.results);
      } catch (error) {
        //
      }
    },

    async getUserReviews({ state, commit }) {
      try {
        commit('startLoading');
        let queryParam =
          '?page=' +
          state.reviewPagination.page +
          '&perPage=' +
          state.reviewPagination.perPage;
        const response = await http.get('my-account/reviews' + queryParam);
        commit('setUserReviews', response.data.results);
      } catch (error) {
        //
      }
    },

    async getVouchers({ commit }) {
      try {
        commit('startLoading');
        const response = await http.get('my-account/vouchers');
        commit('setUserVouchers', response.data.results);
      } catch (error) {
        //
      }
    },

    async selectAddress({ commit }, data) {
      try {
        let response = {};
        switch (data.type) {
          case 'delivery':
            response = await http.put('contact/' + data.id + '/select');
            commit('updateDeliveryData', response.data.results);
            break;
          case 'billing':
            response = await http.put('billing/' + data.id + '/select');
            commit('updateBillingData', response.data.results);
            break;
          case 'company':
            response = await http.put('company/' + data.id + '/select');
            commit('updateCompanyData', response.data.results);
            break;
          default:
            break;
        }
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async addData({ commit }, data) {
      try {
        let response = {};
        switch (data.type) {
          case 'delivery':
            response = await http.post('contact/add', data.inputs);
            commit('updateDeliveryData', response.data.results);
            break;
          case 'billing':
            response = await http.post('billing/add', data.inputs);
            commit('updateBillingData', response.data.results);
            break;
          case 'company':
            response = await http.post('company/add', data.inputs);
            commit('updateCompanyData', response.data.results);
            break;
          default:
            break;
        }
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async updateData({ commit }, data) {
      try {
        let response = {};
        switch (data.type) {
          case 'delivery':
            response = await http.put(
              'contact/' + data.inputs.id + '/change',
              data.inputs
            );
            commit('updateDeliveryData', response.data.results);
            break;
          case 'billing':
            response = await http.put(
              'billing/' + data.inputs.id + '/change',
              data.inputs
            );
            commit('updateBillingData', response.data.results);
            break;
          case 'company':
            response = await http.put(
              'company/' + data.inputs.id + '/change',
              data.inputs
            );
            commit('updateCompanyData', response.data.results);
            break;
          default:
            break;
        }
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async deleteData({ commit }, data) {
      try {
        let response = {};
        switch (data.type) {
          case 'delivery':
            response = await http.delete('contact/' + data.id);
            commit('updateDeliveryData', response.data.results);
            break;
          case 'billing':
            response = await http.delete('billing/' + data.id);
            commit('updateBillingData', response.data.results);
            break;
          case 'company':
            response = await http.delete('company/' + data.id);
            commit('updateCompanyData', response.data.results);
            break;
          default:
            break;
        }
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async updateProfile({ commit }, data) {
      try {
        let response = {};
        response = await http.put('my-account/profile', data);
        commit('updateGeneralData', response.data.results);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async updateLanguage() {
      try {
        await http.put('my-account/profile/language');
      } catch (error) {
        //
      }
    },

    async removeAvatar({ commit }) {
      try {
        await http.delete('auth/avatar');
        commit('removeAvatar');
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async replaceAvatar({ commit }, avatar) {
      try {
        let response = {};
        response = await http.post('auth/avatar', avatar);
        commit('updateAvatar', response.data.results);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async getDataForReturnForm({ commit }) {
      try {
        const response = await http.get('my-account/products');
        if (response.data.results) {
          commit('setReturnData', response.data.results);
        }
      } catch (error) {
        //
      }
    },
  },
};

user.mutations.initUserState(user.state);

export default user;
