import { http } from '@/http';
import router from '@/router';
import { queryParams } from '@/services/queryParams';
import { updateQuery } from '@/services/updateQuery';

const category = {
  namespaced: true,
  state: {
    category: {
      slug: '',
    },
    products: {
      page: {},
      elements: [],
      data: {},
    },
    filtersCount: 0,
    filters: {
      attributes: {
        filter_key: '',
        elements: [],
      },
      manufacturers: {
        filter_key: '',
        elements: [],
      },
      price: {
        filter_key: '',
        elements: [],
      },
      type: {
        filter_key: '',
        elements: [],
      },
      rating: {
        filter_key: '',
        elements: [],
      },
    },
    activeFilters: {
      manufacturers: [],
      price: '',
      rating: '',
      type: '',
      attributes: [],
    },
    categories: {
      elements: [],
    },
    searchKey: '',
    category_slug: '',
    loading: false,
    updatedQuery: false,
    mobileFiltersActive: false,
    mobileFilterAccordions: false,
  },

  mutations: {
    setProducts(state, payload) {
      state.categories = payload.categories;
      state.category = payload.category ? payload.category : '';
      state.products = payload.products;
      state.filters = payload.filters;
      state.filtersCount = payload.filters_count;
      state.activeFilters = {
        manufacturers: Object.prototype.hasOwnProperty.call(
          payload.query_params,
          'manufacturers'
        )
          ? payload.query_params.manufacturers
          : [],
        price: Object.prototype.hasOwnProperty.call(
          payload.query_params,
          'price'
        )
          ? payload.query_params.price
          : {},
        rating: Object.prototype.hasOwnProperty.call(
          payload.query_params,
          'rating'
        )
          ? payload.query_params.rating
          : '',
        type: Object.prototype.hasOwnProperty.call(payload.query_params, 'type')
          ? payload.query_params.type
          : '',
        attributes: Object.prototype.hasOwnProperty.call(
          payload.query_params,
          'attributes'
        )
          ? payload.query_params.attributes
          : [],
      };
      state.searchKey = Object.prototype.hasOwnProperty.call(
        payload.query_params,
        'key'
      )
        ? payload.query_params.key
        : '';
      state.category_slug = Object.prototype.hasOwnProperty.call(
        payload.query_params,
        'category_slug'
      )
        ? payload.query_params.category_slug
        : '';
      state.loading = false;
    },
    setActiveFilters(state, payload) {
      state.activeFilters[payload.key] = payload.value;
    },
    toggleAccordions(state, payload) {
      state.mobileFilterAccordions = payload;
    },
    startLoading(state) {
      state.loading = true;
    },
    setUpdatedQuery(state, payload) {
      state.updatedQuery = payload;
    },
    resetSearchKey(state) {
      state.searchKey = '';
    },
    setMobileFiltersStatus(state, status) {
      state.mobileFiltersActive = status;
    },
  },
  actions: {
    async getProducts({ commit, state }, param) {
      try {
        let params = router.currentRoute.params;
        let slug = params.slug3
          ? params.slug3
          : params.slug2
          ? params.slug2
          : params.slug1;

        let qParams = queryParams(state.activeFilters.attributes, param);
        commit('startLoading');
        let response = '';

        if (!param.isSearch) {
          response = await http.post('categories/' + slug, qParams);
        } else {
          response = await http.post('search/listing', qParams);
        }

        //update url with current query params based on active filters
        commit('setProducts', response.data.results);
        updateQuery(response, qParams);
      } catch (error) {
        router.push({ name: 'NotFound' }).catch(() => {
          //
        });
      }
    },
    async getMoreProductsInfiniteScroll({ commit, state }, data) {
      try {
        let params = router.currentRoute.params;
        let slug = params.slug3
          ? params.slug3
          : params.slug2
          ? params.slug2
          : params.slug1;
        let param = {
          query: { type: 'perPage', value: { perPage: data.perPage } },
          isSearch: data.isSearch,
        };

        let qParams = queryParams(state.activeFilters.attributes, param);
        let response = '';
        if (!data.isSearch) {
          response = await http.post('categories/' + slug, qParams);
        } else {
          response = await http.post('search/listing', qParams);
        }

        //update url with current query params based on active filters
        commit('setProducts', response.data.results);
        // updateQuery(response, qParams);
      } catch (error) {
        router.push({ name: 'NotFound' }).catch(() => {
          //
        });
      }
    },
    async updateFilters({ commit }, { key, value }) {
      commit('setActiveFilters', { key, value });
    },
    updateQuery({ commit }, isButton) {
      commit('setUpdatedQuery', isButton);
    },
  },
};
export default category;
