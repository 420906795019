import { http } from '@/http';

const navigation = {
  namespaced: true,
  state: {
    categories: {},
    loading: true,
  },

  mutations: {
    setCategories(state, payload) {
      state.categories = payload;
      state.loading = false;
    },
    startLoading(state) {
      state.loading = true;
    },
  },
  actions: {
    async getCategories({ commit }) {
      try {
        commit('startLoading');
        const response = await http.get('categories');
        commit('setCategories', response.data.results);
      } catch (error) {
        //
      }
    },
  },
};
export default navigation;
