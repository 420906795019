import axios from 'axios';
import { i18n } from 'vue-lang-router';

import localEnv from '@/config/local.env';
import prodEnv from '@/config/prod.env';
import store from '@/store/index';

import router from './router';
import {
  handleBadRequest,
  handleForbidden,
  handleNetworkError,
  handleNotFound,
  handleServerErrors,
  handleUnauthorized,
  handleUnprocessable,
} from './services/responseErrors';
const apiUrl =
  process.env.NODE_ENV === 'production' ? prodEnv.API_URL : localEnv.API_URL;
export const http = axios.create({
  baseURL: apiUrl,
});
import VueCookies from 'vue-cookies';

http.interceptors.request.use(
  (req) => {
    req.headers.common['Authorization'] = `Bearer ${VueCookies.get('token')}`;
    req.headers.common['Accept-language'] = i18n.locale;
    req.headers.common['Content-Type'] = 'application/json';
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === 'Network Error') {
      handleNetworkError();
      store.dispatch('general/setNoNetworkHeaderAndFooter', true);
      router.push({ name: 'NoNetwork' }).catch(() => {
        //
      });
      return Promise.reject(error);
    }

    switch (error.response.status) {
      case 400:
        handleBadRequest(
          i18n.messages[i18n.locale].notification.response.badRequest
        );
        break;
      case 401:
        handleUnauthorized(error);
        router.push({ name: 'Home' }).catch(() => {
          //
        });
        break;
      case 403:
        handleForbidden(error.response.data.message);
        break;
      case 404:
        handleNotFound(error);
        break;
      case 410:
        handleBadRequest(
          i18n.messages[i18n.locale].notification.response.badRequest
        );
        break;
      case 422:
        handleUnprocessable(error);
        break;
      case 500:
        handleServerErrors(
          i18n.messages[i18n.locale].notification.response.serverError
        );
        break;
      case 503:
        if (router.history.current.name != 'Mentenanta') {
          store.dispatch('general/setMaintenance');
          router.push({ name: 'Mentenanta' }).catch(() => {
            //
          });
        }
        break;
      default:
        break;
    }

    return Promise.reject(error);
  }
);
