import Vue from 'vue';
import LangRouter from 'vue-lang-router';
import Router from 'vue-router';

import localizedURLs from '@/lang/localized-urls';
import translations from '@/lang/translations';
import { checkSummaryStorage } from '@/services/checkSummaryStorage';
import { resetStorage } from '@/services/resetStorage';
import { resetUserData } from '@/services/resetUserData';
import store from '@/store/index';

// Importing components using lazy loading
const Cart = () => import(/* webpackChunkName: "cart" */ '@/pages/Cart');
const Category = () =>
  import(/* webpackChunkName: "category" */ '@/pages/Category');
const Checkout = () =>
  import(/* webpackChunkName: "checkout" */ '@/pages/Checkout');
const Compare = () =>
  import(/* webpackChunkName: "compare" */ '@/pages/Compare');
const Home = () => import(/* webpackChunkName: "home" */ '@/pages/Home');
const NotFound = () =>
  import(/* webpackChunkName: "not-found" */ '@/pages/NotFound');
const NoNetwork = () =>
  import(/* webpackChunkName: "no-network" */ '@/pages/NoNetwork');
const Page = () => import(/* webpackChunkName: "page" */ '@/pages/Page');
const Catalogue = () =>
  import(/* webpackChunkName: "catalogue" */ '@/pages/Catalogue');
const Product = () =>
  import(/* webpackChunkName: "product" */ '@/pages/Product');
const MyAccount = () =>
  import(/* webpackChunkName: "my-account" */ '@/pages/MyAccount');
const MyAddresses = () =>
  import(/* webpackChunkName: "my-addresses" */ '@/pages/MyAddresses');
const MyVouchers = () =>
  import(/* webpackChunkName: "my-vouchers" */ '@/pages/MyVouchers');
const OrderHistory = () =>
  import(/* webpackChunkName: "order-history" */ '@/pages/OrderHistory');
const OrderDetail = () =>
  import(/* webpackChunkName: "order-detail" */ '@/pages/OrderDetail');
const Search = () => import(/* webpackChunkName: "search" */ '@/pages/Search');
const Summary = () =>
  import(/* webpackChunkName: "summary" */ '@/pages/Summary');
const Wishlist = () =>
  import(/* webpackChunkName: "wishlist" */ '@/pages/Wishlist');
const Reviews = () =>
  import(/* webpackChunkName: "reviews" */ '@/pages/Reviews');
const Maintenance = () =>
  import(/* webpackChunkName: "maintenance" */ '@/pages/Maintenance');
const Voucher = () =>
  import(/* webpackChunkName: "voucher" */ '@/pages/Voucher');
const LoginCallback = () =>
  import(/* webpackChunkName: "login-callback" */ '@/pages/LoginCallback');

Vue.use(Router);
Vue.use(LangRouter, {
  defaultLanguage: 'ro',
  translations,
  localizedURLs,
});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/pagina/:slug',
    name: 'Pagina',
    component: Page,
  },
  {
    path: '/cataloage',
    name: 'Catalog',
    component: Catalogue,
  },
  {
    path: '/comparare/:code',
    name: 'Comparare',
    component: Compare,
  },
  {
    path: '/categorie/:slug1/:slug2?/:slug3?',
    name: 'Categorie',
    component: Category,
  },
  {
    path: '/produs/:slug',
    name: 'Produs',
    component: Product,
  },
  {
    path: '/cosul-meu',
    name: 'CosulMeu',
    component: Cart,
  },
  {
    path: '/card-cadou',
    name: 'Voucher',
    component: Voucher,
  },
  {
    path: '/cosul-meu/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.dispatch('general/setCustomHeaderAndFooter', true);
      if (store.state.cart.total.count > 0) {
        next();
      } else {
        next({ name: 'CosulMeu' });
      }
    },
  },
  {
    path: '/cosul-meu/summar',
    name: 'Summar',
    component: Summary,
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      store.dispatch('general/setCustomHeaderAndFooter', true);
      if (store.state.cart.total.count > 0) {
        // check if all storage values are set before go to the summary page if not -> checkout page
        if (checkSummaryStorage()) {
          next();
        } else {
          next({ name: 'Checkout' });
        }
      } else {
        next({ name: 'CosulMeu' });
      }
    },
  },
  {
    path: '/user/contul-meu',
    name: 'ContulMeu',
    component: MyAccount,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/adresele-mele',
    name: 'AdreseleMele',
    component: MyAddresses,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/comenzi',
    name: 'Comenzi',
    component: OrderHistory,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/comenzi/:id',
    name: 'DetaliiComanda',
    component: OrderDetail,
    meta: { requiresAuth: true },
  },
  {
    path: '/user/vouchere-si-cupoane',
    name: 'CupoaneleMele',
    component: MyVouchers,
    meta: { requiresAuth: true },
  },
  {
    path: '/favorite',
    name: 'Favorite',
    component: Wishlist,
  },
  {
    path: '/user/recenzii',
    name: 'ReviewurileMele',
    component: Reviews,
    meta: { requiresAuth: true },
  },
  {
    path: '/cautare',
    name: 'Cautare',
    component: Search,
    beforeEnter: (to, from, next) => {
      if (!to.query.key || to.query.key.length < 3) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/auth/confirmare/:token',
    name: 'ConfirmareRegistrare',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/verify', to.params.token);
      if (store.state.auth.confirmed) {
        store.commit('general/setModal', {
          show: true,
          type: 'login',
          data: { toast: true },
        });
      }
      next({ name: 'Home' });
    },
  },
  {
    path: '/auth/resetare-parola/:token',
    name: 'ResetareParola',
    beforeEnter: async (to, from, next) => {
      store.commit('general/setModal', {
        show: true,
        type: 'reset',
        data: {
          token: to.params.token,
          email: to.query.email,
        },
      });
      next({ name: 'Home' });
    },
  },
  {
    path: '/auth/google/callback',
    name: 'AutentificarePrinGoogle',
    component: LoginCallback,
    beforeEnter: async (to, from, next) => {
      if (!to.query.code) {
        next({ name: 'Home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/mentenanta',
    name: 'Mentenanta',
    component: Maintenance,
    beforeEnter: async (to, from, next) => {
      if (store.state.general.maintenanceMode) {
        next();
      } else {
        await store.dispatch('general/checkMaintenance');
        if (!store.state.general.maintenanceMode) {
          next({ name: 'Home' });
        } else {
          next();
        }
      }
    },
  },
  {
    path: '/eroare',
    name: 'NoNetwork',
    component: NoNetwork,
    beforeEnter: async (to, from, next) => {
      if (store.state.general.customNetworkHAF) {
        next();
      } else {
        next({ name: 'Home' });
      }
    },
  },
  {
    path: '/404',
    alias: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new LangRouter({
  mode: 'history',
  routes,
  async scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve) => {
        resolve({
          left: savedPosition.x,
          top: savedPosition.y,
          behavior: 'instant',
        });
      });
    } else {
      if (from.path != to.path) {
        return { x: 0, y: 0, behavior: 'instant' };
      }
    }
  },
});

window.popStateDetected = false;
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
});

router.beforeEach(async (to, from, next) => {
  const BackOrForward = window.popStateDetected;
  window.popStateDetected = false;
  store.commit('general/setScrollBasedComponentVisibility', false);
  if (from.name != 'CosulMeu' && to.name == 'Checkout') {
    store.dispatch('general/setRouterLoadedFlag', false);
  }
  if (to.name == 'Categorie' || to.name == 'Cautare') {
    let isButton = false;
    if (BackOrForward) {
      isButton = true;
    }
    store.dispatch('category/updateQuery', isButton);
    Vue.nextTick(() => {
      store.dispatch('category/updateQuery', false);
    });
  }
  // check if no internet mode on before load a page content

  if (store.state.general.customNetworkHAF && to.name != 'NoNetwork') {
    await store.dispatch('general/checkNetwork');
    if (store.state.general.customNetworkHAF) {
      next({ name: 'NoNetwork' });
    }
  }

  // check if maintenance mode on before load a page content
  if (store.state.general.maintenanceMode && to.name != 'Mentenanta') {
    await store.dispatch('general/checkMaintenance');
    if (store.state.general.maintenanceMode) {
      return false;
    } else {
      checkOtherConditions(to, from, next);
    }
  } else {
    checkOtherConditions(to, from, next);
  }
});

router.afterEach(() => {
  store.dispatch('general/setRouterLoadedFlag', true);
});

async function checkOtherConditions(to, from, next) {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // open modal if user is not authenticated and redirect to home
    if (!store.state.auth.token) {
      if (from.name != 'CosulMeu') {
        next({ name: 'Home' });
        resetStorage();
      } else {
        resetUserData();
      }
      store.dispatch('auth/setToRedirectPathAfterLogin', to.path);
      store.commit('general/setModal', {
        show: true,
        type: 'login',
      });
      return false;
    }
  }
  // disable custom header and footer if user comes from checkout or summary page
  if (
    (from.name === 'Checkout' || from.name === 'Summar') &&
    to.name !== 'Summar' &&
    to.name !== 'Checkout'
  ) {
    store.dispatch('general/setCustomHeaderAndFooter', false);
  }

  // display cookie banner if it is not acccepted yet
  if (!localStorage.getItem('cookieIsAccepted')) {
    store.dispatch('general/getCookieBanner');
  }

  await checkDefaultLanguage(to, from, next);
}

async function checkDefaultLanguage(to, from, next) {
  // If the language flag is the default language,
  // remove the language flag from the URL

  const defaultLang = 'ro';
  const toPath = to.path;

  if (toPath.startsWith(`/${defaultLang}`)) {
    next(toPath.replace(`/${defaultLang}`, ''));
  } else {
    next();
  }
}
export default router;
