import { http } from '@/http';

const search = {
  namespaced: true,
  state: {
    hints: {},
    loading: false,
  },

  mutations: {
    setHints(state, hints) {
      state.hints = hints;
    },
    startLoading(state) {
      state.loading = true;
    },
  },
  actions: {
    async getHints({ commit }, searchKey) {
      try {
        let response = await http.post('search/dropdown', { key: searchKey });
        //update url with current query params based on active filters
        commit('setHints', response.data.results);
      } catch (error) {
        //router.push({ name: 'NotFound', params: { 0: '/404-not-found' } });
      }
    },
    async storeSearch(_, data) {
      try {
        await http.post('search/store', {
          search_key: data.searchKey,
          product_id: data.productId,
        });
      } catch (error) {
        //router.push({ name: 'NotFound', params: { 0: '/404-not-found' } });
      }
    },
  },
};
export default search;
