import { http } from '@/http';
import { resetCart } from '@/services/resetCartAfterOrderPlaced';

const checkout = {
  namespaced: true,
  state: {
    summaryData: {},
    summaryDeliveryData: null,
    summaryBillingData: null,
    summaryCompanyData: null,
    shippingMethods: [],
    paymentMethods: [],
    deliveryData: [],
    billingData: [],
    companyData: [],
    selectedData: {
      same_data: false,
      person_type: 'individual',
      delivery_method: '',
      payment_type: 'online',
    },
    selectedShipping: { cost: 0 },
    orderId: null,
    summaryError: false,
    sendingError: false,
    loading: false,
  },

  mutations: {
    setCheckoutData(state, payload) {
      let deliveryMethodString = localStorage.getItem('deliveryMethod');
      if (deliveryMethodString) {
        try {
          let deliveryMethod = JSON.parse(deliveryMethodString);
          if (deliveryMethod) {
            let selected = payload.shipping_methods[deliveryMethod.code];
            if (selected) {
              state.selectedShipping.cost = selected.price;
            }
          }
        } catch (error) {
          //
        }
      } else {
        state.selectedShipping.cost =
          payload.summary_data.shipping_method.price;
      }
      state.summaryData = payload.summary_data;
      state.shippingMethods = payload.shipping_methods;
      state.paymentMethods = payload.payment_methods;
      state.selectedData = payload.selected_data;
      state.loading = false;
    },
    setSummaryData(state, payload) {
      state.summaryDeliveryData = payload.delivery_data;
      state.summaryBillingData = payload.billing_data;
      state.summaryCompanyData = payload.company_data;
      state.summaryData = payload.summary_data;
      state.selectedData = payload.selected_data;
      state.loading = false;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setOrderId(state, orderId) {
      state.orderId = orderId;
    },
    setSendingOrderError(state) {
      state.sendingError = true;
      state.loading = false;
    },
    setSummaryError(state, payload) {
      state.summaryError = payload;
      state.loading = false;
    },
  },
  actions: {
    async sendOrder({ commit }) {
      try {
        let data = getValidatedData();
        if (data) {
          const response = await http.post('checkout/send', data);
          if (response.data.results.formUrl) {
            window.location.href = response.data.results.formUrl;
          }
          resetCart();
          commit('setOrderId', response.data.results.order_id);
        }
      } catch (error) {
        commit('setSendingOrderError');
      }
    },
    async getSummaryData({ commit }) {
      try {
        commit('setLoading', true);
        let data = getValidatedData();
        if (data) {
          const response = await http.post('checkout/summary', data);
          commit('setSummaryData', response.data.results);
          commit('setSummaryError', false);
        } else {
          commit('setSummaryError', true);
        }
      } catch (error) {
        commit('setSummaryError', true);
      }
    },
    async getCheckoutData({ commit }) {
      try {
        commit('setLoading', true);
        let data = getValidatedData();
        const response = await http.post('checkout', data);
        commit('setCheckoutData', response.data.results);
      } catch (error) {
        commit('setLoading', false);
      }
    },
    async applyCoupon({ commit }, code) {
      try {
        const encodedCode = encodeURIComponent(code);
        await http.post('promo-codes/' + encodedCode + '/apply');
        let data = getValidatedData();
        if (data) {
          const response = await http.post('checkout', data);
          commit('setCheckoutData', response.data.results);
        }
      } catch (error) {
        //
      }
    },

    async deactivateCoupon({ commit }, code) {
      try {
        const encodedCode = encodeURIComponent(code);
        await http.post('promo-codes/' + encodedCode + '/decline');
        let data = getValidatedData();
        if (data) {
          const response = await http.post('checkout', data);
          commit('setCheckoutData', response.data.results);
        }
      } catch (error) {
        //
      }
    },
  },
};

const getValidatedData = () => {
  let permitedPaymentMethods = ['ramburs', 'online'];
  let permitedShippingMethods = [
    'fan-courier',
    'sameday',
    'predare-personala',
    'email',
  ];
  let permitedPersonTypes = ['individual', 'legal'];
  let permitedSameData = ['true', 'false'];

  let deliveryMethodString = localStorage.getItem('deliveryMethod');
  let deliveryMethod;

  try {
    deliveryMethod = JSON.parse(deliveryMethodString);
  } catch (error) {
    deliveryMethod = { code: 'error' };
  }

  let paymentType = localStorage.getItem('paymentType');
  let personType = localStorage.getItem('personType');
  let sameData = localStorage.getItem('sameData');

  let result = {};

  if (permitedShippingMethods.includes(deliveryMethod.code)) {
    result.delivery_method = deliveryMethod.code;
  }
  if (permitedPaymentMethods.includes(paymentType)) {
    result.payment_type = paymentType;
  }
  if (permitedPersonTypes.includes(personType)) {
    result.person_type = personType;
  }
  if (permitedSameData.includes(sameData)) {
    result.same_data = sameData === 'true';
  }

  return Object.keys(result).length > 0 ? result : null;
};

export default checkout;
