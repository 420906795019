import { http } from '@/http';
import router from '@/router';

const PageStore = {
  namespaced: true,
  state: {
    page: {},
    catalogues: {},
    images: {},
    inputErrors: {},
    loading: false,
  },

  mutations: {
    setPage(state, payload) {
      state.page = payload;
      state.loading = false;
    },
    setCataloguesPage(state, payload) {
      state.catalogues = payload.catalogues;
      state.page = payload.page;
      state.images = payload.images;
      state.loading = false;
    },
    setInputErrors(state, payload) {
      state.inputErrors = payload;
    },
    resetInputErrors(state) {
      state.inputErrors = {};
    },
    startLoading(state) {
      state.loading = true;
    },
  },
  actions: {
    async getPage({ commit }, type) {
      try {
        commit('startLoading');
        const response = await http.get('pages/' + type);
        commit('setPage', response.data.results);
      } catch (error) {
        router.push({ name: 'NotFound' }).catch(() => {
          //
        });
      }
    },
    async getCatalogues({ commit }, query) {
      try {
        let slug = query.slug;
        delete query.slug;
        commit('startLoading');
        const response = await http.post('pages/' + slug, query);
        commit('setCataloguesPage', response.data.results);
      } catch (error) {
        //
      }
    },
    async sendMessage({ commit }, data) {
      try {
        await http.post('pages', data);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },

    async requestReturn({ commit }, data) {
      try {
        await http.post('my-account/return', data);
        commit('resetInputErrors');
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
      }
    },
  },
};
export default PageStore;
