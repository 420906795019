<template>
    <div
        v-if="existData"
        class="cart-info mt-40"
    >
        <div class="d-flex second">
            <truck-icon />
            <p class="lg">
                {{ shippingDetails.product_delivery }}
            </p>
        </div>
        <div class="d-flex">
            <check-circle-icon />
            <p class="lg">
                {{ shippingDetails.product_return }}
            </p>
        </div>
        <div class="d-flex second">
            <package-icon />
            <p class="lg">
                {{ shippingDetails.product_safety }}
            </p>
        </div>
    </div>
</template>

<script>
    import CheckCircleIcon from 'vue-feather-icons/icons/CheckCircleIcon';
    import PackageIcon from 'vue-feather-icons/icons/PackageIcon';
    import TruckIcon from 'vue-feather-icons/icons/TruckIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';
    export default {
        name: 'CartInfo',
        components: {
            PackageIcon,
            TruckIcon,
            CheckCircleIcon,
        },
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState('general', ['shippingDetails']),
            existData() {
                return Object.keys(this.shippingDetails).length > 0;
            },
            lang() {
                return i18n.locale;
            },
        },
        methods:{
            ...mapActions('general', ['getShippingDetails']),
        },
        mounted(){
            this.getShippingDetails();
        },
        watch: {
            lang: {
                handler() {
                    this.getShippingDetails();
                },
                deep: true,
            },
        }
    };
</script>

<style scoped lang="scss">
.cart-info {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  > div {
    align-items: center;
    gap: 12px;
    svg {
      width: 32px;
      height: 32px;
    }
    p {
      flex: 1;
      color: $text;
    }
  }
}
</style>
