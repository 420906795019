import VueCookies from 'vue-cookies';

import { generateToken } from '@/services/generateToken';
export const sessionParam = function () {
  let params = {};
  if (!VueCookies.get('token')) {
    if (!localStorage.getItem('session_key')) {
      localStorage.setItem('session_key', generateToken());
    }
    params = {
      session_key: localStorage.getItem('session_key'),
    };
  }
  return params;
};
