import VueCookies from 'vue-cookies';

import { http } from '@/http';
import { generateToken } from '@/services/generateToken';

const wishlist = {
  namespaced: true,
  state: {
    products: {},
    settings: {},
    navWishlistOpen: false,
    loading: false,
    inputErrors: {},
  },

  mutations: {
    setWishlist(state, payload) {
      state.settings = payload.settings ? payload.settings : {};

      let products = {};
      if (!payload.settings) {
        payload.forEach((product) => {
          products[product.code] = product;
        });
      }

      state.products = products;
      state.loading = false;
      state.inputErrors = {};
    },
    initializeDataFromStorage(state) {
      if (localStorage.wishlistData) {
        let data = JSON.parse(localStorage.wishlistData);
        state.products = data.products;
      } else {
        state.products = {};
      }
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setInputErrors(state, payload) {
      state.inputErrors = payload;
    },
  },
  actions: {
    async getItems({ commit }) {
      try {
        let params = {};
        if (!VueCookies.get('token')) {
          if (!localStorage.getItem('session_key')) {
            localStorage.setItem('session_key', generateToken());
          }
          params = {
            session_key: localStorage.getItem('session_key'),
          };
        }
        commit('setLoading', true);
        const response = await http.post('wishlist', params);
        commit('setWishlist', response.data.results);
      } catch (error) {
        commit('setLoading', false);
      }
    },
    async addItem({ commit }, product) {
      try {
        let params = {};
        if (!VueCookies.get('token')) {
          if (!localStorage.getItem('session_key')) {
            localStorage.setItem('session_key', generateToken());
          }
          params = {
            session_key: localStorage.getItem('session_key'),
          };
        }

        const response = await http.post('wishlist/' + product.id, params);
        commit('setWishlist', response.data.results);
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
        commit('setLoading', false);
      }
    },
    async removeItem({ commit }, productId) {
      try {
        let params = {};
        if (!VueCookies.get('token')) {
          if (localStorage.getItem('session_key')) {
            params = {
              session_key: localStorage.getItem('session_key'),
            };
          }
        }
        commit('setLoading', true);
        const response = await http.delete('wishlist/' + productId, {
          data: params,
        });
        commit('setWishlist', response.data.results);
      } catch (error) {
        commit('setInputErrors', error.response.data.errors);
        commit('setLoading', false);
      }
    },
  },
};
export default wishlist;
