<template>
    <div class="container">
        <div class="navbar-overlay" />
        <desktop-navbar v-if="isNotMobile" />
        <mobile-navbar v-else />
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapGetters, mapState } from 'vuex';

    const DesktopNavbar = () => import(/* webpackChunkName: "navbar-desktop" */ '../controls/navbar/DesktopNavbar');
    const MobileNavbar = () => import(/* webpackChunkName: "navbar-mobile" */ '../controls/navbar/MobileNavbar');

    export default {
        name: 'Navbar',
        components: {
            DesktopNavbar,
            MobileNavbar,
        },
        data(){
            return {
                firstLoad: true,
            };
        },
        computed: {
            ...mapGetters('auth', ['isLogged']),
            ...mapState('navigation', ['categories']),
            isNotMobile() {
                return this.$screen.breakpoint != 'md';
            },
            lang() {
                return i18n.locale;
            },
        },
        methods:{
            ...mapActions('navigation', ['getCategories']),
            async loadCategories() {
                await this.getCategories();
                this.firstLoad = false;
            },
        },
        created() {
            if(Object.keys(this.categories).length == 0){
                this.loadCategories();
            }
        },
        watch: {
            lang: {
                handler() {
                    if(!this.firstLoad){
                        this.loadCategories();
                    }
                },
                deep: true,
            },
        },
    };
</script>
<style lang="scss">
@import 'src/_scss/components/_navbar.scss';
@import 'src/_scss/components/_navbar-dropdown.scss';
</style>
