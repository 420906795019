import router from '@/router';

export const queryParams = function (stateAttributes, param) {
  let queryObject = param.query;
  let filters = {};
  let query = router.currentRoute.query;
  let queryForAttributes = Object.assign({}, query);
  let perPage = null;
  let page = null;
  let sort = '';
  let searchKey = '';
  let category = '';

  let resetAll = Object.prototype.hasOwnProperty.call(param, 'resetAll');

  if (Object.keys(query).length != 0) {
    if (!resetAll) {
      if (Object.prototype.hasOwnProperty.call(query, 'type')) {
        let types = ['new', 'discount', 'featured', 'stock'];
        if (types.indexOf(query.type) !== -1) {
          filters.type = query.type;
          delete queryForAttributes['type'];
        }
      }
      if (Object.prototype.hasOwnProperty.call(query, 'manufacturers')) {
        filters.manufacturers = query.manufacturers.split(',');
        delete queryForAttributes['manufacturers'];
      }
      if (Object.prototype.hasOwnProperty.call(query, 'rating')) {
        let ratings = ['1', '2', '3', '4', '5'];
        if (ratings.indexOf(query.rating) !== -1) {
          filters.rating = query.rating;
        }
        delete queryForAttributes['rating'];
      }
      if (Object.prototype.hasOwnProperty.call(query, 'price')) {
        let prices = query.price.split('-');
        if (
          prices[0].match(/^[0-9]+$/) != null &&
          prices[1].match(/^[0-9]+$/) != null &&
          parseInt(prices[0]) < parseInt(prices[1])
        ) {
          let price = { min: Number(prices[0]), max: Number(prices[1]) };
          filters.price = price;
        }
        delete queryForAttributes['price'];
      }
      if (Object.prototype.hasOwnProperty.call(query, 'category_slug')) {
        category = query.category_slug;
        delete queryForAttributes['category_slug'];
      }
      if (Object.prototype.hasOwnProperty.call(query, 'perPage')) {
        let perPages = [24, 48, 72];

        if (
          query.perPage.match(/^[0-9]+$/) != null &&
          perPages.includes(parseInt(query.perPage))
        ) {
          perPage = query.perPage;
        }
        delete queryForAttributes['perPage'];
      }
      if (Object.prototype.hasOwnProperty.call(query, 'page')) {
        if (query.page.match(/^[0-9]+$/) != null && parseInt(query.page) > 1) {
          page = query.page;
        }
        delete queryForAttributes['page'];
      }
      if (Object.prototype.hasOwnProperty.call(query, 'sort')) {
        sort = query.sort;
        delete queryForAttributes['sort'];
      }
    }
    if (Object.prototype.hasOwnProperty.call(query, 'key')) {
      searchKey = query.key;
      delete queryForAttributes['key'];
    }
  }

  if (Object.keys(queryForAttributes).length > 0 && !resetAll) {
    filters.attributes = {};
    Object.entries(queryForAttributes).forEach(([key, value]) => {
      let attributes = value.split(',');
      filters.attributes[key] = attributes;
    });
  }

  if (queryObject) {
    switch (queryObject.type) {
      case 'filter':
        if (queryObject.key == 'attributes') {
          let updatedAttributeFilter = Object.assign({}, stateAttributes);
          if (
            Object.prototype.hasOwnProperty.call(queryObject.value, 'value')
          ) {
            delete updatedAttributeFilter[queryObject.value.key];
            if (queryObject.value.value.length != 0) {
              updatedAttributeFilter[queryObject.value.key] =
                queryObject.value.value;
            }
          }
          if (Object.keys(updatedAttributeFilter).length != 0) {
            filters.attributes = updatedAttributeFilter;
          } else {
            delete filters['attributes'];
          }
        } else {
          if (queryObject.value != '') {
            filters[queryObject.key] = queryObject.value;
          } else {
            delete filters[queryObject.key];
          }
          page = null;
        }
        break;
      case 'pagination':
        page = queryObject.value.page;
        break;
      case 'perPage':
        perPage = queryObject.value.perPage;
        break;
      case 'sorting':
        sort = queryObject.value;
        break;
      case 'category':
        category = queryObject.value;
        break;
      case 'search':
        if (searchKey != '' && searchKey != queryObject.value) {
          filters = {};
        }
        searchKey = queryObject.value;
        break;
      default:
        break;
    }
  }

  let queryParams = {};
  if (Object.keys(filters).length > 0) {
    queryParams.filters = filters;
  }
  if (perPage) {
    queryParams.perPage = perPage;
  }
  if (page) {
    queryParams.page = page;
  }
  if (sort != '' && sort) {
    queryParams.sort = sort;
  }
  if (searchKey != '' && searchKey && param.isSearch) {
    queryParams.key = searchKey;
  }
  if (category) {
    queryParams.category_slug = category;
  }

  return queryParams;
};
