<template>
    <div id="application-wrap">
        <Header v-if="showComponent" />
        <login-modal
            v-if="showLoginModal"
            :toast="showToast"
        />
        <logout-modal v-if="showLogoutModal" />
        <registration-modal v-if="showRegistrationModal" />
        <request-reset-password-modal v-if="showRequestResetModal" />
        <reset-password-modal v-if="showResetModal" />
        <registration-success-modal v-if="showRegistrationSuccessModal" />
        <re-sent-verification-email-modal v-if="showResentVerificationModal" />
        <section
            class="content"
            :class="{
                custom: isCustomHAF,
                maintenance: maintenanceMode == true,
                'no-network': customNetworkHAF == true,
            }"
        >
            <router-view @updateCookie="updateCookie" />
        </section>
        <Footer v-if="showComponent" />
        <gdpr-cookie-banner
            v-if="showComponent"
            @updateCookie="updateCookie"
        />
        <scroll-to-top />
    </div>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    import Footer from '@/components/layouts/AppFooter';
    import Header from '@/components/layouts/AppHeader';
    import localEnv from '@/config/local.env';
    import prodEnv from '@/config/prod.env';
    import UserAgentMixin from '@/mixins/userAgentMixin';

    const LoginModal = () =>
        import(/* webpackChunkName: "login" */ './components/modals/LoginModal');
    const LogoutModal = () =>
        import(/* webpackChunkName: "logout" */ './components/modals/LogoutModal');
    const RegistrationModal = () =>
        import(
            /* webpackChunkName: "registration" */ './components/modals/RegistrationModal'
        );
    const RegistrationSuccessModal = () =>
        import(
            /* webpackChunkName: "registration-success" */ './components/modals/RegistrationSuccessModal'
        );
    const RequestResetPasswordModal = () =>
        import(
            /* webpackChunkName: "request-reset-password" */ './components/modals/RequestResetPasswordModal'
        );
    const ReSentVerificationEmailModal = () =>
        import(
            /* webpackChunkName: "resent-verification-email" */ './components/modals/ReSentVerificationEmailModal'
        );
    const ResetPasswordModal = () =>
        import(
            /* webpackChunkName: "reset-password" */ './components/modals/ResetPasswordModal'
        );
    const ScrollToTop = () =>
        import(
            /* webpackChunkName: "scroll-to-top" */ './components/controls/ScrollToTop'
        );
    const GdprCookieBanner = () =>
        import(
            /* webpackChunkName: "gdpr-cookie-banner" */ './components/cookie/GdprCookieBanner'
        );

    export default {
        mixins: [UserAgentMixin],
        name: 'MainLayout',
        components: {
            Header,
            Footer,
            RequestResetPasswordModal,
            RegistrationModal,
            LoginModal,
            ResetPasswordModal,
            RegistrationSuccessModal,
            ReSentVerificationEmailModal,
            LogoutModal,
            GdprCookieBanner,
            ScrollToTop,
        },
        data() {
            return {
                showLoginModal: false,
                showRegistrationModal: false,
                showRegistrationSuccessModal: false,
                showLogoutModal: false,
                showRequestResetModal: false,
                showResetModal: false,
                showReSendModal: false,
                showResentVerificationModal: false,
            };
        },
        computed: {
            ...mapState('general', [
                'isCustomHAF',
                'customNetworkHAF',
                'maintenanceMode',
                'modal',
            ]),
            showComponent() {
                return !this.maintenanceMode && !this.customNetworkHAF;
            },
            showToast() {
                return Object.prototype.hasOwnProperty.call(
                    this.modal.data,
                    'toast'
                )
                    ? this.modal.data.toast
                    : false;
            },
            trackingId(){
                return process.env.NODE_ENV === 'production' ? prodEnv.GA_MEASUREMENT_ID : localEnv.GA_MEASUREMENT_ID;
            }
        },
        methods:{
            ...mapMutations('general', [
                'setCookie',
            ]),
            setGAnalytics(){
                const script1 = document.createElement('script');
                script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.trackingId;
                script1.async = true;
                document.head.appendChild(script1);

                script1.onload = () => {
                    const script2 = document.createElement('script');
                    script2.innerHTML = `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '` + this.trackingId + `');
                `;
                    document.head.appendChild(script2);
                };
            },
            removeGAnalytics(){
                const scripts = document.querySelectorAll('script[src*="googletagmanager.com"], script[src*="google-analytics.com"]');
                scripts.forEach(script => script.remove());

                // Remove inline script that initializes gtag
                const inlineScripts = document.querySelectorAll('script');
                inlineScripts.forEach(script => {
                    if (script.innerHTML.includes('gtag(')) {
                        script.remove();
                    }
                });

                if (window.gtag) {
                    window.dataLayer = [];
                    window.gtag = () => {};
                }

                const cookies = document.cookie.split(';');
                cookies.forEach(cookie => {
                    const [cookieName] = cookie.split('=').map(c => c.trim());
                    if (cookieName.startsWith('_g')) {
                        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
                        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=${window.location.hostname}`;
                    }
                });
            },
            updateCookie(cookieAccepted){
                if(cookieAccepted){
                    this.setCookie(true);
                    this.setGAnalytics();
                }else{
                    this.setCookie(false);
                    this.removeGAnalytics();
                }
            },
            trackPageView() {
                if (typeof gtag === 'function') {
                    window.gtag('config', this.trackingId, {
                        'page_path': this.$route.path,
                    });
                }
            },
        },
        mounted() {
            if (!this.isMobileDevice) {
                document.body.classList.add('desktop');
            }
            const consentGiven = localStorage.getItem('cookieIsAccepted') === 'true';
            if(consentGiven){
                this.setCookie(true);
                this.setGAnalytics();
            }

        },
        watch: {
            modal: {
                handler(modal) {
                    switch (modal.type) {
                    case 'login':
                        this.showLoginModal = modal.show;
                        break;
                    case 'logout':
                        this.showLogoutModal = modal.show;
                        break;
                    case 'registration':
                        this.showRegistrationModal = modal.show;
                        break;
                    case 'registrationSuccess':
                        this.showRegistrationSuccessModal = modal.show;
                        break;
                    case 'requestReset':
                        this.showRequestResetModal = modal.show;
                        break;
                    case 'reset':
                        this.showResetModal = modal.show;
                        break;
                    case 'reSend':
                        this.showReSendModal = modal.show;
                        break;
                    case 'reSent':
                        this.showResentVerificationModal = modal.show;
                        break;
                    default:
                        break;
                    }
                },
                deep: true,
            },
            '$route'() {
                if (localStorage.getItem('cookieIsAccepted') === 'true') {
                    this.trackPageView();
                }
            }
        },
        //TODO ADD TRANSLATIONS
        metaInfo: {
            title: 'Cumpără de la noi - Catch them all',
            titleTemplate: '%s | Pescario.ro',
            meta: [
                { charset: 'utf-8' },
                {
                    vmid: 'description',
                    name: 'description',
                    content:
                        'Produse de pescuit de înaltă calitate, unelte și accesorii pentru pescari profesioniști și amatori. Tot ce ai nevoie pentru o experiență de pescuit reușită',
                },
                {
                    name: 'viewport',
                    content:
                        'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes',
                },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'pescario.ro, pescuit, crap',
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: 'Cumpără de la noi - Catch them all',
                },
                { property: 'og:type', content: 'article' },
                {
                    vmid: 'og:url',
                    property: 'og:url',
                    content: window.location.origin,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content:
                        'Produse de pescuit de înaltă calitate, unelte și accesorii pentru pescari profesioniști și amatori. Tot ce ai nevoie pentru o experiență de pescuit reușită',
                },
                {
                    vmid: 'og:image',
                    property: 'og:image',
                    content: 'https://pescario.ro/logo-200.webp',
                },
            ],
            link: [
                { rel: 'dns-prefetch', href: 'https://api.pescario.ro' },
                { rel: 'dns-prefetch', href: 'https://www.gravatar.com' },
                {
                    rel: 'preload',
                    href: '/fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDOUhdTk3j77e.woff2',
                    as: 'font',
                    type: 'font/woff2',
                    crossorigin: 'anonymous',
                },
                {
                    rel: 'preload',
                    href: '/fonts/XRXI3I6Li01BKofiOc5wtlZ2di8HDOUhdTQ3jw.woff2',
                    as: 'font',
                    type: 'font/woff2',
                    crossorigin: 'anonymous',
                },
            ],
        },
    };
</script>