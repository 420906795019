var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.routerLoaded)?_c('footer',{class:{ custom: _vm.isCustomHAF }},[(!_vm.isCustomHAF)?_c('div',{staticClass:"footer-top"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row d-flex"},[_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('span',[_vm._v("Pescario.ro")])])],1),_c('div',{staticClass:"col"},[_c('span',[_vm._v(_vm._s(_vm.$t('footer.firstColumnHeading')))]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'modalitati-de-plata' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.paymentInfoText'))+" ")]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'livrarea-comenzilor' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.shippingInfoText'))+" ")]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: _vm.$t('page.return.slug') },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.returnInfoText'))+" ")]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: _vm.$t('page.guarantee.slug') },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.guaranteeAndServiceInfoText'))+" ")])],1),_c('div',{staticClass:"col"},[_c('span',[_vm._v(_vm._s(_vm.$t('footer.secondColumnHeading')))]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'contact' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.contactUsText'))+" ")]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'despre-noi' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.aboutUsText'))+" ")]),_c('router-link',{attrs:{"to":{ name: 'Pagina', params: { slug: 'faq' } }}},[_vm._v(" "+_vm._s(_vm.$t('footer.faqText'))+" ")])],1),_c('div',{staticClass:"col"},[_c('span',[_vm._v(_vm._s(_vm.$t('footer.thirdColumnHeading')))]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'termeni-si-conditii' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.termsText'))+" ")]),_c('router-link',{attrs:{"to":{ name: 'Pagina', params: { slug: 'gdpr' } }}},[_vm._v(" "+_vm._s(_vm.$t('footer.gdprText'))+" ")]),_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'cookie-uri' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.cookiesText'))+" ")]),_c('a',{attrs:{"href":"https://anpc.ro","target":"_blank"}},[_vm._v(" ANPC ")])],1),_c('div',{staticClass:"col"},[_c('span',[_vm._v(_vm._s(_vm.$t('footer.fourthColumnHeading')))]),_vm._m(0),_vm._m(1)])])])]):_vm._e(),(!_vm.isCustomHAF)?_c('div',{staticClass:"footer-bottom"},[_c('div',{staticClass:"container"},[_c('div',[_c('div',{staticClass:"d-flex"},[(!_vm.isMobile)?_c('div',{staticClass:"d-flex footer-lang"},[_c('globe-icon',{staticClass:"sm"}),_c('p',[_vm._v("Ron | "+_vm._s(_vm.lang))])],1):_vm._e(),_c('div',{staticClass:"copyright"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('footer.copyrightText', { year: _vm.year }))+" ")])])])]),_vm._m(2)])]):_c('div',{staticClass:"custom-footer"},[_c('div',{staticClass:"container"},[_c('cart-info'),_c('div',{staticClass:"footer-info mt-4"},[_c('div',[_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'modalitati-de-plata' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.paymentInfoText'))+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'livrarea-comenzilor' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.shippingInfoText'))+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'formular-returnare-produs' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.returnInfoText'))+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{ name: 'Pagina', params: { slug: 'faq' } }}},[_vm._v(" "+_vm._s(_vm.$t('footer.faqText'))+" ")])],1),_c('div',[_c('router-link',{attrs:{"to":{
                            name: 'Pagina',
                            params: { slug: 'contact' },
                        }}},[_vm._v(" "+_vm._s(_vm.$t('footer.contactUsText'))+" ")])],1)]),_c('div',{staticClass:"footer-copyright mt-4"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('footer.copyrightText', { year: _vm.year }))+" ")])])],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex",attrs:{"href":"https://www.facebook.com/profile.php?id=61564971162990","target":"_blank"}},[_c('img',{attrs:{"src":"/images/icon-facebook.svg","alt":"facebook"}}),_vm._v(" Facebook ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"d-flex",attrs:{"href":"https://www.instagram.com/pescario.ro/?igsh=NHE5MW1lcHowZGJo&utm_source=qr","target":"_blank"}},[_c('img',{attrs:{"src":"/images/icon-instagram.svg","alt":"instagram"}}),_vm._v(" Instagram ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anpc"},[_c('a',{attrs:{"href":"https://anpc.ro/ce-este-sal/","target":"_blank"}},[_c('img',{attrs:{"src":"/images/anpc-sal.webp","alt":"Soluționarea Alternativă a Litigiilor"}})]),_c('a',{attrs:{"href":"https://ec.europa.eu/consumers/odr","target":"_blank"}},[_c('img',{attrs:{"src":"/images/anpc-sol.webp","alt":"Soluționarea online a litigiilor"}})])])
}]

export { render, staticRenderFns }