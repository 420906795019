export const getCompareData = function (data, action) {
  if (action == 'add') {
    let compareData = {};
    let compareCategory = Object.keys(data)[0];
    let newValue = {};
    let newProductCode = data[compareCategory].productCode;
    let newProductThumbnail = data[compareCategory].thumbnail;

    newValue[newProductCode] = newProductThumbnail;

    if (!localStorage.getItem('compare')) {
      // no value added into the storage yet
      compareData[compareCategory] = newValue;
    } else {
      // there is value added
      compareData = JSON.parse(localStorage.compare);
      if (Object.prototype.hasOwnProperty.call(compareData, compareCategory)) {
        // same category

        if (
          Object.keys(compareData[compareCategory]).length != 4 &&
          !Object.prototype.hasOwnProperty.call(compareData[compareCategory], [
            data[compareCategory].productCode,
          ])
        ) {
          // length not reached 4 yet and the product was not added yet
          compareData[compareCategory][newProductCode] = newProductThumbnail;
        }
      } else {
        // new category
        compareData = {};
        compareData[compareCategory] = newValue;
      }
    }

    localStorage.setItem('compare', JSON.stringify(compareData));
    return compareData;
  } else {
    let compareData = localStorage.compare
      ? JSON.parse(localStorage.compare)
      : {};
    let compareCategory = Object.keys(compareData)[0];

    delete compareData[compareCategory][data];
    if (Object.keys(compareData[compareCategory]).length != 0) {
      localStorage.setItem('compare', JSON.stringify(compareData));
    } else {
      localStorage.removeItem('compare');
      delete compareData[compareCategory];
    }
    return compareData;
  }
};
