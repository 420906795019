const webStoragePlugin = (store) => {
  store.subscribe((mutation) => {
    const payload = mutation.payload;
    let products = {};
    let profile = null;
    let profileStorage = {};
    let profileString = localStorage.getItem('profile');

    switch (mutation.type) {
      case 'auth/setToken':
        localStorage.setItem('profile', JSON.stringify(payload.user.data));
        break;
      case 'user/setUserData':
        try {
          profile = JSON.parse(profileString);
          if (profile) {
            profileStorage.avatar = profile.avatar;
          }
        } catch (error) {
          //
        }

        profileStorage.first_name = payload.general_data.first_name;
        profileStorage.last_name = payload.general_data.last_name;
        profileStorage.name = payload.general_data.name;

        localStorage.setItem('profile', JSON.stringify(profileStorage));
        break;
      case 'user/setAvatar':
        try {
          profile = JSON.parse(profileString);
          if (profile) {
            profileStorage.first_name = profile.first_name;
            profileStorage.last_name = profile.last_name;
            profileStorage.name = profile.name;
          }
        } catch (error) {
          //
        }
        profileStorage.avatar = payload;
        localStorage.setItem('profile', JSON.stringify(profileStorage));
        break;
      case 'user/updateGeneralData':
        localStorage.setItem(
          'profile',
          JSON.stringify({
            avatar: '',
            first_name: payload.first_name,
            last_name: payload.last_name,
            name: payload.name,
          })
        );
        break;
      case 'cart/setCartAfterCoupon':
        setStorageForSelectedDeliveryMethod(payload);
        break;
      case 'cart/setCartItems':
        setCartDataStorage(payload);
        break;
      case 'cart/setCart':
        if (payload.products && payload.vouchers) {
          setCartDataStorage(payload);
          setStorageForSelectedDeliveryMethod(payload);
        }
        break;
      case 'wishlist/setWishlist':
        if (!payload.settings) {
          payload.forEach((product) => {
            products[product.code] = product;
          });
        }
        localStorage.setItem(
          'wishlistData',
          JSON.stringify({ products: products })
        );
        break;
      case 'compare/resetCompareData':
        localStorage.removeItem('compare');
        break;
      case 'general/setCounties':
        localStorage.setItem('counties', JSON.stringify(payload.RO));
        break;
      case 'general/setCities':
        localStorage.setItem('cities', JSON.stringify(payload));
        break;
      case 'checkout/setCheckoutData':
        setStorageForSelectedDeliveryMethod(payload);
        setStorageForSelectedData(payload);
        break;
      case 'checkout/setSummaryDataAfterCoupon':
        setStorageForSelectedDeliveryMethod(payload);
        setStorageForSelectedData(payload);
        break;
      case 'checkout/setSummaryData':
        localStorage.setItem(
          'deliveryMethod',
          JSON.stringify({
            code: payload.selected_data.delivery_method,
            cost: payload.summary_data.shipping_method.price,
          })
        );
        setStorageForSelectedData(payload);
        break;
      default:
        break;
    }
  });
};

function setStorageForSelectedData(payload) {
  localStorage.setItem('paymentType', payload.selected_data.payment_type);
  localStorage.setItem('personType', payload.selected_data.person_type);
  localStorage.setItem('sameData', payload.selected_data.same_data);
}

function setStorageForSelectedDeliveryMethod(payload) {
  let deliveryMethodString = localStorage.getItem('deliveryMethod');
  let deliveryMethod;

  try {
    deliveryMethod = JSON.parse(deliveryMethodString);
  } catch (error) {
    deliveryMethod = null;
  }

  let deliveryMethodStorage = null;

  if (deliveryMethodString && deliveryMethod) {
    deliveryMethodStorage = deliveryMethodString;
    let selected = payload.shipping_methods[deliveryMethod.code];
    if (selected) {
      if (selected.price != deliveryMethod.cost) {
        deliveryMethodStorage = JSON.stringify({
          code: selected.code,
          cost: selected.price,
        });
      }
    } else {
      deliveryMethodStorage = getSelectedDeliveryMethod(payload);
    }
  } else {
    deliveryMethodStorage = getSelectedDeliveryMethod(payload);
  }
  localStorage.setItem('deliveryMethod', deliveryMethodStorage);
}

function getSelectedDeliveryMethod(payload) {
  const defaultShippingMethod = Object.values(payload.shipping_methods).find(
    (method) => method.default
  );
  return JSON.stringify({
    code: defaultShippingMethod.code,
    cost: defaultShippingMethod.price,
  });
}

function setCartDataStorage(payload) {
  let products = {};
  let vouchers = {};
  payload.products.forEach((product) => {
    products[product.code] = product;
  });
  payload.vouchers.forEach((voucher) => {
    vouchers[voucher.value] = voucher;
  });
  localStorage.setItem(
    'cartData',
    JSON.stringify({
      total: payload.total
        ? payload.total
        : {
            amount: 0,
            count: 0,
          },
      products: products,
      vouchers: vouchers,
    })
  );
}

export default webStoragePlugin;
