import { http } from '@/http';

const Voucher = {
  namespaced: true,
  state: {
    vouchers: {},
  },

  mutations: {
    setVouchers(state, payload) {
      state.vouchers = payload;
    },
  },
  actions: {
    async getVouchers({ commit }) {
      try {
        const response = await http.get('promo-codes');
        commit('setVouchers', response.data.results);
      } catch (error) {
        //
      }
    },
  },
};
export default Voucher;
