import huFooter from '@/lang/translations/hu/footer.json';
import huForm from '@/lang/translations/hu/form.json';
import huHeader from '@/lang/translations/hu/header.json';
import huMeta from '@/lang/translations/hu/meta.json';
import huModal from '@/lang/translations/hu/modal.json';
import huNotif from '@/lang/translations/hu/notification.json';
import huPage from '@/lang/translations/hu/page.json';
import roFooter from '@/lang/translations/ro/footer.json';
import roForm from '@/lang/translations/ro/form.json';
import roHeader from '@/lang/translations/ro/header.json';
import roMeta from '@/lang/translations/ro/meta.json';
import roModal from '@/lang/translations/ro/modal.json';
import roNotif from '@/lang/translations/ro/notification.json';
import roPage from '@/lang/translations/ro/page.json';

export const translationJsons = (lang = 'ro') => {
  if (lang === 'ro') {
    return Object.assign(
      {},
      roHeader,
      roModal,
      roForm,
      roPage,
      roFooter,
      roNotif,
      roMeta
    );
  }
  return Object.assign(
    {},
    huHeader,
    huModal,
    huForm,
    huPage,
    huFooter,
    huNotif,
    huMeta
  );
};
